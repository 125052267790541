$color_1: #54F2F3;
$color_2: #fff;
$color_3: #EBEBEB;
$color_4: #CCC;
$color_5: #666666;
$color_6: #000;
$color_7: #AEAEAE;
$color_8: rgba(0, 0, 0, 0.48);
$color_9: #181818;
$color_10: rgba(0, 0, 0, 0.46);
$color_11: #5EB1BF;
$color_12: rgba(0, 0, 0, 0.90);
$color_13: #F4E04D;
$color_14: #ffb851;
$color_15: #212121;
$color_16: #1E1E1E;
$color_17: #ffffffba;
$color_18: #FFF;
$font-family_1: "Gotham Pro";
$font-family_2: 'Roboto';
$font-family_3: Gotham Pro;
$font-family_4: Roboto;
$background-color_1: #F5F5F5;
$background-color_2: #092E2F;

@font-face {
	font-family: "Gotham Pro";
	src: url("/src/Components/Assets/Fonts/Gotham/GothamPro.woff2") format("woff2"), url("/src/Components/Assets/Fonts/Gotham/GothamPro.woff") format("woff"), url("/src/Components/Assets/Fonts/Gotham/GothamPro.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Gotham Pro";
	src: url("/src/Components/Assets/Fonts/Gotham/GothamPro-Bold.woff2") format("woff2"), url("/src/Components/Assets/Fonts/Gotham/GothamPro-Bold.woff") format("woff"), url("/src/Components/Assets/Fonts/Gotham/GothamPro-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Gotham Pro";
	src: url("/src/Components/Assets/Fonts/Gotham/GothamPro-Light.woff2") format("woff2"), url("/src/Components/Assets/Fonts/Gotham/GothamPro-Light.woff") format("woff"), url("/src/Components/Assets/Fonts/Gotham/GothamPro-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Gotham Pro";
	src: url("/src/Components/Assets/Fonts/Gotham/GothamPro-Medium.ttf") format("woff2"), url("/src/Components/Assets/Fonts/Gotham/GothamPro-Medium.woff") format("woff"), url("/src/Components/Assets/Fonts/Gotham/GothamPro-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-Medium.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Medium.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-Regular.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Regular.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-Light.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Light.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-BoldItalic.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-ThinItalic.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-MediumItalic.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-LightItalic.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-LightItalic.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-Italic.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Italic.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-Thin.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Thin.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-BlackItalic.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-Black.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Black.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/src/Components/Assets/Fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Bold.woff') format('woff'), url('/src/Components/Assets/Fonts/Roboto/Roboto-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
/*# sourceMappingURL=style.css.map */
body {
	font-family: $font-family_1 !important;
	background: #f8f8f8 !important;
}
.side-tab {
	p {
		font-family: $font-family_2 !important;
	}
	background: #fff;
	border-radius: 16px;
	.list-unstyled {
		.mb-3 {
			a {
				text-decoration: none;
			}
			.d-flex {
				p {
					color: $color_5;
				}
				span.new {
					background: #F4E04D;
					color: $color_6;
					position: absolute;
					right: 0;
					font-size: 11px;
					border-radius: 20px;
					padding: 10px 8px;
					display: flex;
					line-height: 0px;
					top: 2px;
				}
			}
		}
		.mb-0 {
			a {
				text-decoration: none;
			}
			.d-flex {
				p {
					color: $color_5;
				}
				span.new {
					background: #F4E04D;
					color: $color_6;
					position: absolute;
					right: 0;
					font-size: 11px;
					border-radius: 20px;
					padding: 10px 8px;
					display: flex;
					line-height: 0px;
					top: 2px;
				}
			}
		}
	}
}
h3 {
	font-family: $font-family_2 !important;
}
h4 {
	font-family: $font-family_2 !important;
}
a {
	font-family: $font-family_2 !important;
}
.main {
	padding-top: 26px;
}
.navbar {
	background: #092E2F !important;
}
a.navbar-brand {
	margin: 0 1.25rem !important;
}
.navbar-nav {
	.nav-item {
		img.active {
			filter: invert(0.5) sepia(1) saturate(5) hue-rotate(135deg);
		}
		&:hover {
			img {
				filter: invert(0.5) sepia(1) saturate(5) hue-rotate(135deg);
				color: $color_1;
			}
			a {
				filter: invert(0.5) sepia(1) saturate(5) hue-rotate(135deg);
				color: $color_1;
			}
		}
		a {
			color: $color_2;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 400;
			font-family: $font-family_3;
			color: $color_2 !important;
			padding: 6px 0 0 0 !important;
		}
	}
	.nav-link.active {
		color: $color_1 !important;
	}
	.navbar-nav {
		.nav-link.show {
			color: $color_1 !important;
		}
	}
}
.profile {
	img {
		width: 100%;
	}
	p {
		color: $color_2;
		font-size: 12px;
	}
}
.search-bar {
	width: 300px;
	input {
		border-radius: 6.561px;
		background: transparent;
		border: none;
		color: $color_3;
		font-family: $font-family_3;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		padding: 14px 8px;
		line-height: normal;
		&::-moz-placeholder {
			color: $color_4;
			color: $color_4;
		}
		&:focus {
			background: transparent;
			outline: none;
			border: none;
			color: $color_3;
			box-shadow: none;
		}
		&::placeholder {
			color: $color_4;
		}
	}
	.d-flex {
		background: rgba(94, 177, 191, 0.1);
		border-radius: 6px;
		padding: 0px 15px;
	}
}
.post-tabs {
	background: transparent !important;
	border-radius: 16px;
	li.nav-item {
		text-align: center;
		width: 50%;
		border-bottom: 2px solid transparent !important;
		button {
			width: 100%;
			text-align: center;
			width: 100%;
			text-align: center;
			color: $color_6;
			padding: 10px 0;
		}
	}
	.nav-tabs {
		.nav-item.show {
			.nav-link {
				background: #072C2D;
				color: $color_2;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				border-bottom: 2px solid #54F2F3 !important;
			}
		}
		.nav-link.active {
			background: #072C2D;
			color: $color_2;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			border-bottom: 2px solid #54F2F3 !important;
		}
	}
	.card {
		.post-description {
			color: $color_6;
			font-family: $font-family_4;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		margin-bottom: 20px;
		border-radius: 18px;
		.uploaded-image {
			border-radius: 16px;
		}
		&:nth-child(1) {
			margin-bottom: 20px;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
			border-bottom-left-radius: 18px;
			border-bottom-right-radius: 18px;
		}
	}
}
.second-tab {
	margin-top: 20px;
	.title-with-icon {
		.titel {
			h3 {
				font-size: 16px;
				font-weight: 400;
				text-transform: uppercase;
				text-align: left;
			}
		}
		p {
			font-size: 11px;
			text-transform: uppercase;
			color: $color_7;
		}
	}
}
.right-pannel {
	margin-top: 20px;
	.title-with-icon {
		.titel {
			h3 {
				font-size: 16px;
				font-weight: 500;
				text-transform: capitalize;
			}
		}
	}
	.titel.text-start {
		p {
			font-size: 10px;
		}
	}
}
.title-with-icon.d-flex.justify-content-between.position-relative {
	border-bottom: 1px solid #F4F4F4;
	margin-bottom: 20px;
}
ul.posts-list {
	li {
		.post-thumb {
			width: 100%;
			border-radius: 4px;
			max-width: 50px;
			height: 50px;
			object-fit: cover;
		}
		a {
			.post-content {
				h4 {
					font-size: 12px;
					margin-bottom: 2px;
					font-family: $font-family_2;
					font-weight: 400;
					color: $color_6;
				}
			}
			text-decoration: none;
		}
		.post-content {
			p {
				font-family: $font-family_2;
				color: $color_8;
				font-family: $font-family_4;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}
.stars-quantity {
	position: absolute;
	right: 4px;
	bottom: 4px;
	background: #fff;
	padding: 0 6px;
	height: 18px;
	border-radius: 100px;
	position: absolute;
	right: 4px;
	bottom: 4px;
	background: #fff;
	padding: 0 6px;
	border-radius: 100px;
	img {
		width: 34px;
	}
}
.posts-list {
	li {
		border-radius: 8px;
		background: #ECF9FF;
		padding: 5px;
		margin-bottom: 6px;
		margin-right: 6px;
	}
}
ul.posts-list.list-unstyled {
	height: 194px;
	overflow-y: scroll;
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		background-color: $background-color_1;
		border-radius: 14px;
	}
	&::-webkit-scrollbar {
		width: 2px;
		background-color: $background-color_1;
		border-radius: 14px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $background-color_2;
		border-radius: 14px;
	}
}
.hashtags-list {
	a {
		color: $color_6;
		background: #f8f8f8;
		padding: 3px 11px;
		border-radius: 6px;
		margin: 10px 10px 0px 0px;
		text-decoration: none;
		font-size: 12px;
		width: fit-content;
	}
}
.filters {
	.title-with-icon {
		margin-bottom: 10px !important;
	}
	position: sticky;
	top: 458px;
}
.write-post {
	border-radius: 16px;
	background: var(--general-surface, #FFF);
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.14);
	padding: 20px 0 0 0;
	textarea {
		border: none;
		font-size: 16px;
		resize: none;
		font-family: $font-family_2;
		width: 100%;
		font-weight: 400;
		color: $color_5;
		&:focus-visible {
			outline: none;
		}
	}
	.user-field {
		padding: 0 20px;
	}
	.btn-group.d-flex {
		background: #D2F0FF;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		button {
			border: 0px;
			color: $color_5;
			font-size: 16px;
			padding: 10px 25px;
			background: transparent;
		}
		.send {
			border-bottom-right-radius: 16px;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
			background: rgba(161, 225, 255, 0.49);
		}
	}
	button.send {
		&:hover {
			background: #a3e2ff !important;
		}
	}
}
.user-field {
	img {
		width: 100%;
		max-width: 50px;
		object-fit: cover;
		height: 50px;
		border-radius: 100px;
	}
}
.nav-link {
	.show {
		display: none;
		display: block;
	}
}
.hide {
	display: none;
}
.nav-link.active {
	.show {
		display: none;
	}
	.hide {
		display: block;
	}
}
.card {
	.user-details {
		h3 {
			color: $color_9;
			font-family: $font-family_3 !important;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-transform: capitalize;
		}
		p {
			color: $color_10;
			font-family: $font-family_4;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
	a.options {
		img {
			max-width: 20px !important;
			object-fit: contain;
			width: 100%;
			height: unset;
		}
	}
	.button-group {
		.set-one {
			a {
				&:hover {
					img {
						filter: invert(0.5) sepia(1) saturate(5) hue-rotate(148deg);
					}
				}
			}
		}
	}
}
.set-one {
	a {
		span {
			position: absolute;
			top: -6px;
			font-size: 11px;
			background: #F4E04D;
			width: auto;
			padding: 1px 7px;
			border: 2px solid #fff;
			right: -28px;
			border-radius: 12px;
			color: $color_6;
		}
	}
}
span.chats {
	position: absolute;
	top: -6px;
	font-size: 11px;
	background: #F4E04D;
	width: auto;
	padding: 1px 3px !important;
	right: -12px !important;
	border: 2px solid #fff;
	border-radius: 12px;
	color: $color_6;
}
.message-one {
	.user-info {
		.user {
			width: 100%;
			border-radius: 100px;
			max-width: 40px;
			height: 40px;
			object-fit: cover;
		}
	}
}
.user-info {
	width: 10%;
}
.time-options {
	width: 12%;
	span {
		font-size: 10px;
		color: $color_9;
		font-weight: 100;
	}
}
.sr-message {
	width: 80%;
	p {
		font-size: 14px;
	}
}
.feedback {
	a {
		color: $color_11;
		font-size: 13px;
		font-weight: 300;
	}
}
.comments {
	h4 {
		font-size: 16px;
		font-weight: 500;
	}
}
span.round-yellow {
	background: #f4e04d;
	padding: 2px 6px;
	font-size: 14px;
	font-weight: 400;
	border-radius: 50px;
}
.write-message {
	.user {
		width: 100%;
		border-radius: 100px;
		max-width: 34px;
		height: 34px;
		left: 8px;
		position: absolute;
		object-fit: cover;
	}
	.sending-opt {
		position: absolute;
		right: 8px;
	}
}
.write-message.d-flex {
	input[type="text"] {
		width: 100%;
		border-radius: 18px;
		background: #F6F6F6;
		color: $color_12;
		font-family: $font-family_4;
		font-size: 14px;
		font-style: normal;
		padding: 14px 93px 14px 53px;
		font-weight: 400;
		line-height: normal;
		border: none;
		&:focus-visible {
			outline: none;
		}
	}
}
ul#myTab {
	background: #fff;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.rating-box {
	position: absolute;
	right: 28px;
	border-radius: 16.074px;
	background: rgba(255, 255, 255, 0.98);
	padding: 4px;
	.stars {
		display: flex;
		align-items: center;
		gap: 4px;
	}
}
.stars {
	i {
		font-size: 16px;
		color: $color_13;
		transition: all 0.2s;
		cursor: pointer;
	}
	i.active {
		color: $color_14;
		transform: scale(1.2);
	}
}
.connection-detail {
	h3 {
		font-family: $font-family_4;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		color: $color_6;
	}
}
.connection-list {
	li {
		a {
			text-decoration: none;
			.connection-detail {
				p {
					font-family: $font-family_4;
					font-size: 11px;
					font-style: normal;
					font-weight: 300;
					color: $color_15;
					line-height: normal;
				}
			}
			img {
				width: 40px;
				height: 40px;
				border-radius: 100%;
			}
		}
	}
}
button.connect {
	border-radius: 8px;
	border: 1px solid #5EB1BF;
	background: #FFF;
	padding: 6px 12px;
	color: $color_16;
	font-family: $font-family_4;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	&:hover {
		background: #092c2d;
		color: $color_2;
	}
}
ul.connection-list {
	li {
		margin-bottom: 1rem;
		&:nth-last-child(1) {
			margin-bottom: 0rem;
		}
	}
}
.advertise {
	img {
		width: 100%;
		border-radius: 18px;
	}
	position: sticky;
	top: 103px;
}
.footer {
	border-radius: 18px;
	background: #042A2B;
	margin-top: 20px;
	position: sticky;
	top: 433px;
	a {
		&:hover {
			text-decoration: underline;
		}
		color: $color_17;
		font-family: $font-family_4;
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		padding: 0px 5px;
		border-radius: 6px;
		margin: 0px 10px 10px 0px;
		text-decoration: none;
	}
	.copyright {
		p {
			.footer-logo {
				width: 40px;
				object-fit: contain;
			}
		}
	}
}
.copyright {
	p {
		color: $color_18;
		text-align: center;
		font-family: $font-family_4;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	border-top: 1px solid #1d3f40;
}
.popular-posts {
	position: sticky;
	top: 103px;
}
nav.navbar {
	position: fixed;
	z-index: 1;
	width: 100%;
	top: 0px;
}
.container.main {
	margin-top: 4.7rem !important;
}
@media only screen and (max-width: 991px) {
	.right {
		display: none;
	}
}
