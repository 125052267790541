@font-face {
  font-family: "Gotham Pro";
  src: url("/src/Components/Assets/Fonts/Gotham/GothamPro.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Gotham/GothamPro.woff") format("woff"),
    url("/src/Components/Assets/Fonts/Gotham/GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("/src/Components/Assets/Fonts/Gotham/GothamPro-Bold.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Gotham/GothamPro-Bold.woff")
      format("woff"),
    url("/src/Components/Assets/Fonts/Gotham/GothamPro-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("/src/Components/Assets/Fonts/Gotham/GothamPro-Light.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Gotham/GothamPro-Light.woff")
      format("woff"),
    url("/src/Components/Assets/Fonts/Gotham/GothamPro-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("/src/Components/Assets/Fonts/Gotham/GothamPro-Medium.ttf")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Gotham/GothamPro-Medium.woff")
      format("woff"),
    url("/src/Components/Assets/Fonts/Gotham/GothamPro-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-Medium.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Medium.woff") format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-Regular.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Regular.woff")
      format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-Light.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Light.woff") format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-BoldItalic.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-BoldItalic.woff")
      format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-BoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-ThinItalic.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-ThinItalic.woff")
      format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-ThinItalic.ttf")
      format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-MediumItalic.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-MediumItalic.woff")
      format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-MediumItalic.ttf")
      format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-LightItalic.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-LightItalic.woff")
      format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-LightItalic.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-Italic.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Italic.woff") format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Italic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-Thin.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Thin.woff") format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Thin.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-BlackItalic.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-BlackItalic.woff")
      format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-BlackItalic.ttf")
      format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-Black.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Black.woff") format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/Components/Assets/Fonts/Roboto/Roboto-Bold.woff2")
      format("woff2"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Bold.woff") format("woff"),
    url("/src/Components/Assets/Fonts/Roboto/Roboto-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Gotham Pro" !important;
  background: #f8f8f8 !important;
}

* {
  transition: 0.3s all ease;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
}

a.navbar-brand img {
 max-width: 100%;
 width: 100%;
}

.side-tab {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 2px 0px rgba(0, 0, 0, 0.14);
    padding: 15px;
}

.side-tab p {
  font-family: "Roboto" !important;
}

.side-tab .list-unstyled li a {
  text-decoration: none;
}

.side-tab .list-unstyled .mb-3 .d-flex p {
  color: #666666;
}

.side-tab .list-unstyled .mb-3 .d-flex span.new {
  background: #f4e04d;
  color: #000;
  position: absolute;
  right: 0;
  font-size: 11px;
  border-radius: 20px;
  padding: 10px 8px;
  display: flex;
  line-height: 0px;
  top: 2px;
}

.side-tab .list-unstyled .mb-0 a {
  text-decoration: none;
}

.side-tab .list-unstyled .mb-0 .d-flex p {
  color: #666666;
}

.side-tab .list-unstyled .mb-0 .d-flex span.new {
  background: #f4e04d;
  color: #000;
  position: absolute;
  right: 0;
  font-size: 11px;
  border-radius: 20px;
  padding: 10px 8px;
  display: flex;
  line-height: 0px;
  top: 2px;
}

h3 {
  font-family: "Roboto" !important;
}

h4 {
  font-family: "Roboto" !important;
}

a {
  font-family: "Roboto" !important;
}

.main {
  padding-top: 26px;
}

.navbar {
  background: #092e2f !important;
}

a.navbar-brand {
  margin: 0 1.25rem !important;
}

.navbar-nav .nav-item img.active,
.navbar-nav .nav-link.active .net-img  {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(135deg);
}

.navbar-nav .nav-item:hover img {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(135deg);
  color: #54f2f3;
}

.navbar-nav .nav-item:hover a {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(135deg);
  color: #54f2f3;
}

.navbar-nav .nav-item a {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  font-family: Gotham Pro;
  color: #fff !important;
  padding: 6px 0 0 0;
}

.navbar-nav .nav-link.active {
  color: #54f2f3 !important;
}

.navbar-nav .navbar-nav .nav-link.show {
  color: #54f2f3 !important;
}

.guest-navbar .navbar-nav .nav-link.signin-btn {
  border-radius: 6px;
  border: 1px solid #54f2f3;
  padding: 8px 30px;
}

.guest-navbar .navbar-nav .nav-link.join-btn {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.profile img {
  width: 100%;
}

.profile p {
  color: #fff;
  font-size: 10px;
  font-family: "Gotham Pro";
}

.search-bar {
  width: 300px;
}

.search-bar input {
  border-radius: 6.561px;
  background: transparent;
  border: none;
  color: #ebebeb;
  font-family: Gotham Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 14px 8px;
  line-height: normal;
}

.search-bar input::-moz-placeholder {
  color: #ccc;
  color: #ccc;
}

.search-bar input:focus {
  background: transparent;
  outline: none;
  border: none;
  color: #ebebeb;
  box-shadow: none;
}

.search-bar input::-moz-placeholder {
  color: #ccc;
}

.search-bar input::placeholder {
  color: #ccc;
}

.search-bar .d-flex {
  background: rgba(94, 177, 191, 0.1);
  border-radius: 6px;
  padding: 0px 15px;
}

.post-tabs {
  background: transparent !important;
  border-radius: 16px;
}

.post-tabs li.nav-item {
  text-align: center;
  width: 50%;
  border-bottom: 2px solid transparent !important;
}

.post-tabs li.nav-item button {
  width: 100%;
  text-align: center;
  width: 100%;
  text-align: center;
  color: #000;
  padding: 10px 0;
}

.post-tabs .nav-tabs .nav-item.show .nav-link {
  background: #072c2d;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 2px solid #54f2f3 !important;
}

.post-tabs .nav-tabs .nav-link.active {
  background: #072c2d;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 2px solid #54f2f3 !important;
}

.post-tabs .card {
  margin-bottom: 20px;
  border-radius: 18px;
}

.post-tabs .card .post-description {
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
}

.post-tabs .card .uploaded-image,
.post-img-wrapper .uploaded-image {
  border-radius: 16px;
  max-height: 380px;
  object-fit: cover;
}

.post-tabs .card:nth-child(1) {
  margin-bottom: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.second-tab {
  margin-top: 20px;
}

.second-tab .title-with-icon .titel h3 {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
}

.second-tab .title-with-icon p {
  font-size: 11px;
  text-transform: uppercase;
  color: #aeaeae;
}

.right-pannel {
  margin-top: 20px;
}

.right-pannel .title-with-icon .titel h3 {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.right-pannel .titel.text-start p {
  font-size: 10px;
}

.title-with-icon.d-flex.justify-content-between.position-relative {
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 20px;
}

ul.posts-list li .post-thumb {
  width: 100%;
  border-radius: 4px;
  max-width: 50px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
}

ul.posts-list li .post-content h4 {
  font-size: 12px;
  margin-bottom: 2px;
  font-family: "Roboto";
  font-weight: 400;
  color: #000;
}

ul.posts-list li .post-content p {
  font-family: "Roboto";
  color: rgba(0, 0, 0, 0.48);
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.stars-quantity {
  position: absolute;
  right: 4px;
  bottom: 4px;
  background: #fff;
  padding: 0 6px;
  height: 18px;
  border-radius: 100px;
  position: absolute;
  right: 4px;
  bottom: 4px;
  background: #fff;
  padding: 0 6px;
  border-radius: 100px;
  font-size: 10px;
}

.stars-quantity img {
  width: 34px;
}

.posts-list li {
  border-radius: 8px;
  background: #ecf9ff;
  padding: 5px;
  margin-bottom: 6px;
  margin-right: 6px;
}

ul.posts-list.list-unstyled {
  height: 194px;
  overflow-y: scroll;
}

ul.posts-list.list-unstyled::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 14px;
}

ul.posts-list.list-unstyled::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
  border-radius: 14px;
}

ul.posts-list.list-unstyled::-webkit-scrollbar-thumb {
  background-color: #092e2f;
  border-radius: 14px;
}

.hashtags-list span,
.hashtags-list input[type="checkbox"] + label {
  color: #000;
  background: #f8f8f8;
  padding: 3px 5px;
  border-radius: 6px;
  margin: 10px 10px 0px 0px;
  text-decoration: none;
  font-size: 12px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.hashtags-list input[type="checkbox"]:checked + label {
  background-color: #042a2b;
  color: #54f2f3;
}

/* .filters {
  position: sticky;
  top: 458px;
} */

.filters .title-with-icon {
  margin-bottom: 10px !important;
}

.write-post {
  border-radius: 16px;
  background: var(--general-surface, #fff);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 2px 0px rgba(0, 0, 0, 0.14);
  padding: 10px 0 0 0;
}

.write-post textarea {
  border: none;
  font-size: 16px;
  resize: none;
  font-family: "Roboto";
  width: 100%;
  font-weight: 400;
  color: #666666;
}

.write-post textarea:focus-visible {
  outline: none;
}

.write-post .user-field {
  padding: 0 20px;
}

.write-post .btn-group.d-flex {
  background: #d2f0ff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 0 0px 0 15px;
}

.write-post .btn-group.d-flex button {
  border: 0px;
  color: #666666;
  font-size: 16px;
  padding: 10px 15px;
  background: transparent;
}

.write-post .btn-group.d-flex .send {
  border-bottom-right-radius: 16px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background: rgba(161, 225, 255, 0.49);
}

.write-post button.send:hover {
  background: #a3e2ff !important;
}

.user-field img {
  max-width: 100%;
  width: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  height: 50px;
  border-radius: 100px;
}

.user-field .dropdown .dropdown-toggle,
.horizontal-icon .dropdown .dropdown-toggle {
  background: #fff !important;
  padding: 0 5px;
  border: 0 !important;
}

.user-field .dropdown .btn-light.dropdown-toggle:focus,
.user-field .dropdown .btn-light.dropdown-toggle:visited,
.user-field .dropdown .btn-light.dropdown-toggle:hover,
.user-field .dropdown .btn-light.dropdown-toggle:active {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.user-field .dropdown img {
  width: auto;
  object-fit: unset;
  height: auto;
  border-radius: 100px;
}

.user-field .dropdown .dropdown-toggle::after,
.horizontal-icon .dropdown .dropdown-toggle::after {
  display: none;
}

.nav-link .show {
  display: none;
  display: block;
}

.hide {
  display: none;
}

.nav-link.active .show {
  display: none;
}

.nav-link.active .hide {
  display: block;
}

.card .user-details h3 {
  color: #181818;
  font-family: Gotham Pro !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.card .user-details p {
  color: rgba(0, 0, 0, 0.46);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card a.options img {
  max-width: 20px !important;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: unset;
}

.card .button-group .set-one a:hover img {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(148deg);
}

.set-one a span {
  position: absolute;
  top: -6px;
  right: -12px !important;
  font-size: 10px;
  background: #f4e04d;
  width: 20px;
  padding: 0;
  border: 2px solid #fff;
  right: -28px;
  border-radius: 12px;
  color: #000;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

span.chats {
  position: absolute;
  top: -6px;
  font-size: 11px;
  background: #f4e04d;
  width: auto;
  /* padding: 1px 3px !important; */
  right: -12px !important;
  border: 2px solid #fff;
  border-radius: 12px;
  color: #000;
}

.message-one .user-info .user {
  width: 100%;
  border-radius: 100px;
  max-width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
}

.message-one.grey-cmnt {
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 15px;
}

.user-info {
  width: 10%;
}

.time-options {
  width: 12%;
}

.time-options span {
  font-size: 10px;
  color: #181818;
  font-weight: 100;
}

.sr-message {
  width: 80%;
}

.sr-message p {
  font-size: 14px;
  font-family: "Roboto";
}

.feedback a {
  color: #5eb1bf;
  font-size: 13px;
  font-weight: 300;
}

.comments h4 {
  font-size: 16px;
  font-weight: 500;
}

span.round-yellow {
  background: #f4e04d;
  padding: 2px 6px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 50px;
}

.write-message .user {
  width: 100%;
  border-radius: 100px;
  max-width: 34px;
  height: 34px;
  left: 8px;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
}

.write-message .sending-opt {
  position: absolute;
  right: 8px;
  top: 10px;
}

.write-message.d-flex input[type="text"] {
  width: 100%;
  border-radius: 18px;
  background: #f6f6f6;
  color: rgba(0, 0, 0, 0.9);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  padding: 14px 93px 14px 53px;
  font-weight: 400;
  line-height: normal;
  border: none;
}

.write-message.d-flex input[type="text"]:focus-visible {
  outline: none;
}

ul#myTab {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: #fff;
}

.rating-box {
  position: absolute;
  right: 28px;
  border-radius: 16.074px;
  background: rgba(255, 255, 255, 0.98);
  padding: 4px;
  top: 30px;
}

.rating-box .stars {
  display: flex;
  align-items: center;
  gap: 4px;
}

.stars i {
  font-size: 16px;
  /* color: #f4e04d; */
  transition: all 0.2s;
  cursor: pointer;
}

.stars i.active {
  color: #ffb851;
  transform: scale(1.2);
}

.connection-detail h3 {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #000;
}

.connection-list li a {
  text-decoration: none;
}

.connection-list li a .connection-detail p {
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  color: #212121;
  line-height: normal;
}

.connection-list li a img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

button.connect {
  border-radius: 8px;
  border: 1px solid #5eb1bf;
  background: #fff;
  padding: 6px 12px;
  color: #1e1e1e;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

button.connect:hover {
  background: #092c2d;
  color: #fff;
}

ul.connection-list li {
  margin-bottom: 1rem;
}

ul.connection-list li:nth-last-child(1) {
  margin-bottom: 0rem;
}

.advertise {
  position: sticky;
  top: 103px;
  border-radius: 16px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 2px 0px rgba(0, 0, 0, 0.14);
}

.advertise img {
  width: 100%;
  border-radius: 18px;
}

.footer {
  border-radius: 18px;
  background: #042a2b;
  margin-top: 20px;
  position: sticky;
  top: 433px;
}

.footer a {
  color: rgba(255, 255, 255, 0.7294117647);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  padding: 0px 5px;
  border-radius: 6px;
  margin: 0px 10px 10px 0px;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer .copyright p .footer-logo {
  width: 40px;
  -o-object-fit: contain;
  object-fit: contain;
}

.copyright {
  border-top: 1px solid #1d3f40;
}

.copyright p {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popular-posts {
  position: sticky;
  top: 103px;
}

.sticky-side {
  position: sticky;
  top: 103px;
}

nav.navbar {
  position: fixed;
  z-index: 1050;
  width: 100%;
  top: 0px;
}

.bio .background-thumb img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.container.main {
  margin-top: 4.7rem !important;
}

.bio .profile-thumb {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%);
}

.bio .profile-data p {
  font-family: "Roboto" !important;
  color: #181818;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.bio .profile-thumb img {
  width: 95.862px;
  height: 95.862px;
  flex-shrink: 0;
  border-radius: 95.862px;
  border: 4px solid #fff;
  object-fit: cover;
}

.bio .username-badge {
  color: #000;
  font-family: Gotham Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bio .profile-data {
  margin: 50px 16px 0;
}

.profile-menu .nav-item:hover img {
  filter: none !important;
}

.profile-menu .profile img.profile-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-menu .nav-item:hover a {
  filter: none !important;
}

.profile-data .profile-image .avatar-profile-image {
  position: relative;
  top: -42px;
  font-size: 100px;
  border: 8px solid #fff;
}

ul.connection-list.list-unstyled {
  max-height: 250px;
  overflow-y: auto;
}

.profile .thumb {
  width: 100%;
  max-width: 40px;
  border-radius: 100%;
  height: 40px;
  object-fit: cover;
}

.profile img.arrow {
  width: 100%;
  max-width: 6px;
}

ul.dropdown-menu.show {
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
  top: 51px;
  width: 232px;
  left: -109px;
  border-radius: 8px;
  border: none;
}

.opt-profile .profile-opt img {
  width: 100%;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.opt-profile .profile-opt .user-detail h3 {
  color: #181818;
  font-family: Gotham Pro !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.opt-profile .profile-opt .user-detail p {
  color: rgba(0, 0, 0, 0.46);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.opt-profile.px-4.py-4 .btn-outline-darkblue {
  width: 100%;
  background: transparent;
  border-radius: 6px;
  border: 1px solid #092e2f;
  color: #000;
  text-align: center;
  font-family: Gotham Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.opt-profile.px-4.py-4 .btn-outline-darkblue:hover {
  background: #092e2f;
  color: #fff;
}

.account-menu label {
  color: rgba(24, 24, 24, 0.6);
  font-family: Gotham Pro;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.account-menu ul li {
  border-radius: 8px;
  border: 0.814px solid #f4f4f4;
  background: #fff;
}

/* .account-menu ul li a {
  padding-top: 0px!important;
} */
.account-menu ul li a p {
  color: #000;
}

button.btn.btn-darkblue {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #092e2f;
  color: #fff;
  text-align: center;
  font-family: Gotham Pro !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  background: #092e2f;
  line-height: normal;
}

button.btn.btn-darkblue:hover {
  opacity: 0.9;
}

img.premium {
  width: 100%;
  border-radius: 0px !important;
  max-width: 16px !important;
  height: 16px !important;
  object-fit: contain;
}

.profile-menu ul.dropdown-menu {
  position: absolute !important;
}

.message-header {
  background: #042a2b;
  color: #fff;
}

.message-list {
  border-radius: 16px;
  background: #fcfdfd;
  height: 100vh;
}

.message-header {
  background: #042a2b;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.message-list .message-header h4 {
  color: #fff;
  font-family: Gotham Pro !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.message-list .search {
  border-bottom: 1px solid #eee;
}

.message-list .search input[type="search"] {
  width: 100%;
  border-radius: 3.835px;
  background: rgba(94, 177, 191, 0.1);
  border: none;
  color: #737373;
  font-family: Roboto;
  font-size: 12.464px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.message-list .search input[type="search"]:focus-visible {
  outline: none;
}

.message-list .search-icon {
  position: absolute;
  top: 3px;
  left: 22px;
}

.message-list .search-filter {
  position: absolute;
  right: 28px;
  top: 3px;
}

.messages ul li a .user-stat img,
.searched-users ul li .user-details img {
  width: 100%;
  max-width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 100%;
}

.message-box .searched-users {
  max-height: 300px;
  overflow-y: auto;
}

.messages ul li a {
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #eee;
}
.message-box .searched-users .user-details {
  text-decoration: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.message-box .searched-users .user-details:hover {
  background-color: rgba(94, 177, 191, 0.1);
}

.message-box .searched-users .user-details p {
  font-size: 12px;
}

.messages ul li a .name-message h4,
.message-box .searched-users .user-details h4 {
  color: #3c3c3c;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.messages ul li a .name-message.text-start {
  width: 70%;
}

.messages ul li a .name-message p.message {
  color: #a7a7a7;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.messages ul li .message-option img {
  max-width: 100%;
  width: 16px;
}

.messages ul li a:hover {
  background: rgba(94, 177, 191, 0.1);
}

.messages ul li .active {
  background: rgba(94, 177, 191, 0.1);
}

.messages ul li .active:before {
  background: #54f2f3;
  position: absolute;
  left: 0;
  content: "";
  width: 6px;
  height: 60px;
}

.messages ul li a .user-stat span.status img {
  width: 100%;
  max-width: 13px;
  height: 13px;
  object-fit: contain;
}

.messages ul li a .user-stat span.status {
  position: absolute;
  bottom: -6px;
  right: 10px;
}

.message-box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
}

.message-box-header {
  color: #000;
}

.message-box-header h4 {
  color: #181818;
  font-family: Gotham Pro !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.message-box-header h4 .current-status {
  opacity: 0.6;
  color: #000;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.message-box-header h4 span {
  color: #5eb1bf;
}

.recieved-messages .recieved .user-stat img {
  max-width: 100%;
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 100%;
}

.recieved-messages .recieved .user-stat .status img {
  width: 100%;
  max-width: 13px;
  height: 13px;
  object-fit: cover;
  border-radius: 100%;
}

.recieved-messages .recieved .user-stat {
  width: fit-content;
}

.recieved-messages.sender .recieved .user-stat.me-3 {
  margin-left: 1rem !important;
}

.recieved-messages .recieved .user-stat span.status {
  position: absolute;
  top: 25px;
  right: 0px;
}

.msg-check span p {
  color: #181818 !important;
  text-align: right;
  font-family: Gotham Pro !important;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 400;
  background: transparent !important;
}

.msg-check.text-start p,
.msg-check.text-start .applied-job-discription {
  background: #173a3b;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border-radius: 6px;
}

.recieved-messages.sender.mx-3.my-3 .recieved.d-flex {
  flex-direction: row-reverse;
}

/* .message-box {
  height: 100vh;
} */

.recieved-messages.sender .msg-check.text-start p,
.recieved-messages.sender .msg-check.text-start .applied-job-discription {
  background: #ecf6f7;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border-radius: 6px;
}

.edit-opt a:hover img {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(135deg);
}

.message-field input#imageInput {
  display: none;
}

.message-field textarea {
  border-radius: 6px;
  background: #f6f6f6;
  width: 100%;
  color: rgba(24, 24, 24, 0.5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  resize: none;
}

.message-field textarea:focus-visible {
  outline: none;
}

.message-field .text-end button:hover {
  background: #54f2f3;
}

.message-field .text-end button {
  border-radius: 6px;
  border: 1px solid #54f2f3;
  background: #fff;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 7px 24px;
}

.message-field {
  border-top: 1px solid #eee;
}

.chat-widget {
  width: 300px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: none;
  /* Initially hidden */
}

.bio {
  margin-bottom: 20px;
}

.profile-menu .dropdown-toggle::after {
  display: none;
}

.search-bar button {
  background: transparent;
  border: none;
  display: none;
}

/* .message-field .d-flex label {
  opacity: 0;
} */

.filled {
  color: gold;
}

.blank {
  color: gray;
}

.rating-container {
  display: flex;
  align-items: center;
}

.star {
  color: gray;
  cursor: pointer;
}

/* .message-field {
    background: #fff;
    position: absolute;
    width: 100%;
    bottom: calc(10% - 0px);
} */
.flex-box {
  height: 445px;
  overflow: hidden;
  overflow-y: auto;
}

.flex-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.flex-box::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.flex-box::-webkit-scrollbar-thumb {
  background-color: #5db1bf;
  border: 0px solid #555555;
}

.navbar-nav .nav-item a span {
  font-family: "Gotham Pro" !important;
  font-size: 10px;
}

.navbar-nav .nav-item a span.font-lg {
  font-size: 18px;
}

.navbar-nav .nav-item a span.count {
  position: absolute;
  height: 18px;
  display: flex;
  width: 18px;
  border-radius: 50%;
  background-color: #00cacf;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  right: 28px;
  top: 0;
}

.search-dropdown {
  position: absolute;
  top: 110%;
  left: 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.search-dropdown .searched-list li {
  padding: 10px 15px 10px 30px;
  background-color: #fff;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  width: 400px;
}

.search-dropdown .searched-list li:hover {
  background-color: #ebf7fd;
}

.stats ul.stat-list h2 {
  color: #5eb1bf;
  font-family: Gotham Pro;
  font-size: 43.256px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.stats ul.stat-list p {
  color: #181818;
  font-family: Gotham Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.stats .title-with-icon a {
  color: #5eb1bf;
  text-align: right;
  font-family: Gotham Pro !important;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: uppercase;
}

div#v-pills-tab {
  background: #fff;
  border-radius: 6px;
}

.nav-pills .nav-link.active:before {
  background: #5eb1bf;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 4px;
  height: 55px;
}

.tab-boxes.settings ul.list-unstyled li .d-flex button:hover {
  background: #f3f3f340;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #f1fdffad !important;
  color: #000 !important;
}

.nav-pills .nav-link {
  border-radius: 0px !important;
  color: #000;
  color: #434343;
  font-family: Gotham Pro !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.nav-pills:hover .nav-link {
  color: #000;
}

.nav-pills .nav-link img {
  width: 100%;
  max-width: 19px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0, -50%);
}

p.stat-title {
  color: #181818;
  text-align: center;
  font-family: Gotham Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

p.stat-title:before {
  content: "";
  background: #e7e7e7;
  height: 1px;
  width: 44%;
  position: absolute;
  left: 0;
  top: 4px;
}

div#v-pills-home .notify-user {
  width: 100%;
}

div#v-pills-home p.stat-title::after {
  content: "";
  background: #e7e7e7;
  height: 1px;
  width: 44%;
  position: absolute;
  right: 0;
  top: 4px;
}

.notify-user img {
  width: 100%;
  border-radius: 100px;
  max-width: 40px;
  height: 40px;
  object-fit: cover;
}

#v-pills-tabContent .message-one {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 20.611764907836914px 61.835296630859375px 0px
    rgba(241, 244, 248, 0.5);
}

.notification-message p {
  color: #181818;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.notification-message span.time {
  color: #181818;
  font-family: Gotham Pro !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.main.login img.login-svg {
  width: 100%;
  max-width: 80%;
}

.login .form-holder {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.login .form-content .page-links {
  margin-bottom: 34px;
}

.login .form-content .form-items h3 {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: "Gotham Pro" !important;
}

.login .form-content .page-links a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #000;
  font-weight: 300;
  font-size: 15px;
  margin-right: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.login button#submit {
  border-radius: 8px;
  border: 1px solid #5eb1bf;
  background: #fff;
  padding: 8px 24px;
  color: #1e1e1e;
  font-family: "Gotham Pro";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login .form-content .page-links a:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  bottom: -10px;
  background-color: rgb(237 237 237 / 98%);
  transition: all 0.3s ease;
}

.login button#submit:hover {
  background: #5eb1bf;
  color: #fff;
}

.login .form-content input,
.login .form-content .dropdown-toggle.btn-default {
  width: 100%;
  padding: 9px 20px;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 14px;
}

.login .form-content {
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 60px;
  min-height: 100%;
}

.login .form-content .other-links a {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
  margin-right: 10px;
}

.login .form-content .other-links span {
  color: #000;
  font-size: 12px;
  font-weight: 300;
  margin-right: 20px;
}

.login .form-content .page-links a.active:after {
  background-color: #092e2f;
}

.login .form-content .form-button a {
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  color: #000;
}

.tab-boxes.settings {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 20.611764907836914px 61.835296630859375px 0px
    rgba(241, 244, 248, 0.5);
}

.tab-boxes.settings ul.list-unstyled li .d-flex button {
  width: 100%;
  background: #fff;
}

.tab-boxes.settings ul.list-unstyled li .d-flex button {
  width: 100%;
  border-bottom: 1px solid #f2f2f2 !important;
  background: #fff;
}

.tab-boxes.settings.py-4.px-3 h4.text-start {
  color: #181818;
  font-family: Roboto !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.profile-page .row .center .stats {
  margin-top: 0;
  margin-bottom: 20px;
}

.profile-page .row .center .stats .footer {
  margin-bottom: 20px;
}

.account-menu ul li a:active {
  background: #f8f9fa;
}

.container.main.profile-page .footer {
  margin-bottom: 20px;
}

.edit-profile {
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
}

.edit-profile .bg-buttons a:hover {
  opacity: 0.8;
}

.edit-profile .bg-buttons {
  /* background-image: url(/src/Components/Assets/images/bg.webp); */
  height: 185px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-buttons a.vertical-opt img {
  width: 100%;
  max-width: 3.5px;
}

.bg-buttons a.ed-profile {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10.29411792755127px 30.882354736328125px 0px
    rgba(113, 123, 133, 0.05);
  position: absolute;
  right: 60px;
  width: fit-content;
  top: 20px;
  padding: 8px 16px;
  color: #181818;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  text-decoration: none;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.bg-buttons a.vertical-opt {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10.29411792755127px 30.882354736328125px 0px
    rgba(113, 123, 133, 0.05);
  position: absolute;
  right: 20px;
  width: 32px;
  top: 20px;
  height: 32px;
}

.bg-buttons a.upload-bg {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10.29411792755127px 30.882354736328125px 0px
    rgba(113, 123, 133, 0.05);
  position: absolute;
  left: 20px;
  width: 32px;
  top: 20px;
  height: 32px;
}

.profile-data .profile-image img {
  width: 185px;
  height: 185px;
  border: 8px solid #fff;
  border-radius: 100%;
  object-fit: cover;
  position: relative;
  top: -42px;
  background-color: #fff;
}

.name-location h4 {
  color: #181818;
  font-family: Gotham Pro;
  font-size: 18.529px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.name-location .p-description p {
  color: rgba(0, 0, 0, 0.46);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
}

.profile-data .name-location .p-description .contact-info {
  border-radius: 6px;
  background: linear-gradient(180deg, #202125 0%, #1b5052 100%);
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: Gotham Pro !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s all ease;
  text-transform: uppercase;
  padding: 12px 16px;
}

.profile-data .name-location .p-description .contact-info:hover {
  opacity: 0.9;
}

.profile-data .name-location .location-icon {
  color: #181818;
  font-family: Gotham Pro;
  font-size: 12.353px;
  font-style: normal;
  font-weight: 400;
}

.profile-data .name-location .p-description a.connections {
  border-radius: 6px;
  border: 1px solid #1b5052;
  text-decoration: none;
  color: #000;
  text-align: center;
  font-family: Gotham Pro !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  transition: 0.3s all ease;
  padding: 12px 16px;
}

.profile-data .name-location .p-description a.connections:hover {
  background: linear-gradient(180deg, #202125 0%, #1b5052 100%);
  color: #fff;
}

.profile-tabs .nav-item button {
  background: white;
  color: #000;
  width: 100%;
  text-align: center;
  font-family: Gotham Pro;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  padding: 14px 0px;
  line-height: normal;
  text-transform: uppercase;
}

.profile-tabs .nav-item.show .nav-link,
.profile-tabs .nav-item .nav-link.active {
  background: linear-gradient(180deg, #0b3334 0%, #174a4b 100%);
  color: #fff;
}

.profile-tabs .nav-item button {
  background: white;
  color: #000;
  width: 100%;
  text-align: center;
  font-family: Gotham Pro;
  border: 1px solid #f2f2f2;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  padding: 14px 0px;
  line-height: normal;
  text-transform: uppercase;
}

.profile-bio {
  border-radius: 16px;
  background: #fff;
}

.profile-bio .title-with-icon .titel h3 {
  color: #181818;
  font-family: Gotham Pro;
  font-size: 18.529px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.profile-bio p {
  color: rgba(0, 0, 0, 0.46);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
}

a.common-btn,
.reply-btnn {
  color: #5eb1bf !important;
  text-align: center;
  font-family: Gotham Pro !important;
  font-size: 12px !important;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  float: right;
  padding-top: 6px;
  text-decoration: underline !important;
}

.reply-btnn:focus {
  box-shadow: unset !important;
}

.reply-counts {
  top: 5px;
  right: -12px !important;
  background: #f4e04d;
  border-radius: 12px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  font-size: 12px !important;
}

.name-location .total-rating span {
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

a.common-btn:hover {
  text-decoration: underline;
}

/*==== search result page css start ======*/

.search-profile-wraper,
.post-wrapper {
  border-radius: 16px;

  background: var(--general-surface, #fff);

  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 2px 0px rgba(0, 0, 0, 0.14);

  padding: 10px 0 0 0;
}

.side-search-title h5 {
  font-size: 13px;

  font-style: normal;

  font-weight: 300;

  color: #212121;
}

.side-search-list li a {
  font-size: 15px;

  font-style: normal;

  font-weight: 400;

  color: #000;
}

.side-search-title h5 {
  font-size: 13px;

  font-style: normal;

  font-weight: 300;

  color: #212121;
}

.search-profile-wraper .user-field .company-detail h4.company-title {
  font-size: 20px;

  margin-bottom: 5px;
}

.search-profile-wraper .user-field .company-detail p.subheading {
  font-size: 14px;

  margin: 0;

  line-height: 1;
}

.search-profile-wraper .user-field .company-detail .bottom_heading {
  font-size: 14px;

  color: grey;

  line-height: 2;
}

.company-detail .follower-area svg {
  fill: grey;

  margin-right: 5px;
}

.company-detail .follower-area p {
  color: gray;

  font-size: 14px;
}

.following-btn {
  font-size: 14px !important;
}

.view-page-btn {
  border-color: gray !important;

  font-size: 14px !important;

  color: gray !important;
}

.view-page-btn:hover {
  color: #fff !important;
}

.post-heading {
  font-size: 16px;

  font-weight: 500;
}

.post-wrapper .user-field .user-details h3,
.post-wrapper .post-description {
  font-size: 15px;
}

.post-wrapper .user-field .user-details p {
  font-size: 14px;

  color: grey;

  line-height: 15px;
}

.follow-butnn {
  color: #5eb1bf;

  font-size: 17px;

  font-weight: 500;
}

.people-list .company-title {
  font-size: 14px !important;
}

.people-list .subheading,
.people-list .bottom_heading,
.people-list .follower-area p {
  font-size: 13px !important;
}

/*==== search result page css end ======*/

.profile-posts .title-with-icon .titel h3 span {
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.profile-posts .row .col-md-4 img {
  width: 100%;
  height: 165px;
  object-fit: cover;
  border-radius: 6px;
}

.profile-posts .row .col-md-4 h4 {
  color: #181818;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.profile-posts .row .col-md-4 p span {
  color: #181818;
  font-family: Gotham Pro !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.profile-page .followers {
  position: sticky;
  top: 102px;
}

.profile-page .people {
  position: sticky;
  top: 358px;
}

.profile-page .footer {
  position: sticky;
  top: 442px !important;
}

.Skills,
.experience,
.education {
  border-radius: 16px;
  background: #fff;
}

.Skills .title-with-icon .titel h3,
.experience .title-with-icon .titel h3 {
  color: #181818;
  font-family: Gotham Pro;
  font-size: 18.529px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Skills .row .col-md-4 p,
.experience.row .col-md-4 p {
  color: #181818;
  text-align: center;
  font-family: Roboto;
  font-size: 14.412px;
  border-radius: 6.176px;
  border: 1.029px solid #f4f4f4;
  font-style: normal;
  font-weight: 400;
  padding: 18px 0;
}

.Skills .row .col-md-4 p:hover {
  border: 1.029px solid #f4f4f4;
  background: #f9f9f9;
}

.experience ul li {
  border-bottom: 1px solid #f4f4f4;
}

.experience ul li .details {
  width: 85%;
}

.experience ul li .details h4 {
  color: #181818;
  font-family: Gotham Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.experience ul li .details p.designation {
  color: #181818;
  font-family: Gotham Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.experience ul li .details p.designation span {
  color: #181818;
  font-family: Gotham Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.experience ul li .details p.years-months {
  color: #181818;
  font-family: Gotham Pro !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.experience ul li .details p.years-months span {
  color: #0275b1;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.experience p.description {
  color: rgba(0, 0, 0, 0.46);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
}

.profile-page .filters {
  position: sticky !important;
  top: unset !important;
}

.profile-page .popular-posts {
  position: sticky !important;
  top: 102px !important;
}

.profile-page .filters {
  position: sticky !important;
  top: 458px !important;
}

.followers {
  background: #fff;
  border-radius: 16px;
  margin-bottom: 20px;
  overflow: hidden;
}

.follower button {
  width: 50%;
  text-align: center;
  color: #000;
}

.follower .nav-tabs .nav-link:hover {
  color: #000;
}

.followers .nav-tabs button {
  color: #000;
  border-bottom: 2px solid transparent !important;
  font-family: Gotham Pro !important;
  font-size: 12px;
  font-style: normal;
  padding: 12px 0;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.followers .nav-tabs button.active {
  background: #072c2d;
  color: #fff;
  border-bottom: 2px solid #54f2f3 !important;
  font-family: Gotham Pro !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

ul.connection-list li:hover {
  background: #ecf9ff;
}

.view-all {
  border-top: 1px solid #f4f4f4;
}

.chalkboard a {
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid #69f4f5;
  padding: 5px 10px;
  color: #181818;
  font-family: Gotham Pro !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chalkboard a:hover {
  background: #69f4f5;
}

.message-box-header {
  border-bottom: 1px solid #f4f4f4;
}

.participants .one label.name {
  position: absolute;
  left: 0;
  bottom: 0;
}

.participants .one label.name {
  position: absolute;
  left: 4px;
  bottom: 4px;
  font-family: Roboto;
  font-size: 10px;
  background: #000;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.2px;
  padding: 2px 6px;
  border-radius: 4px;
}

.participants img {
  border-radius: 6px;
}

.participants .one label.name img {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(135deg);
  width: 100%;
  max-width: 10px;
}

.login .form-content select.form-select option {
  width: 100%;
  padding: 9px 20px;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 300;
  transition: all 0.3s ease;
}

.login .form-content select.form-select {
  width: 100%;
  padding: 9px 20px;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  color: #000;
  transition: all 0.3s ease;
}

.video-chat-btn {
  background: #092e2f;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.video-box .video-chat-btn .btn-with-icon button {
  background: transparent;
  color: #ffff;
}

.video-box .video-chat-btn .btn-with-icon button span {
  display: block;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.video-box img.focused-thumb {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.video-box .video-chat-btn .hold-end .hold {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: normal;
  padding: 8px 18px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: rgba(178, 34, 34, 0);
}

.video-box .video-chat-btn .hold-end .hold:hover {
  background: white;
  color: #000;
}

.video-box .video-chat-btn .hold-end .end {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: normal;
  padding: 8px 18px;
  border-radius: 8px;
  border: 1px solid #b22222;
  background: #b22222;
}

.video-box .video-chat-btn .hold-end .end:hover {
  background: #d22727;
}

.message-box {
  border-radius: 16px;
  height: unset;
}

.people ul.connection-list li:hover {
  background: transparent;
}

ul.connection-list.list-unstyled.p-0.mb-0 {
  height: 230px;
  overflow: hidden;
  overflow-y: scroll;
}

ul.connection-list::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
  border-radius: 14px;
}

ul.connection-list::-webkit-scrollbar-thumb {
  background-color: #092e2f;
  border-radius: 14px;
}

ul.connection-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 14px;
}

.profile-tabs {
  background-color: transparent !important;
}

.container.main.profile-page .messages.mt-4 {
  position: sticky !important;
  top: 101px !important;
}

.settings-btn {
  background: #0a2e2e !important;
  color: #fff !important;
}

.setting-content p.stat-title:before,
.setting-content p.stat-title:after {
  width: 40% !important;
}

.tab-boxes.settings h4.text-start {
  font-size: 20px;
}

.nav-pills button {
  text-transform: capitalize !important;
}

p.stat-title:after {
  content: "";
  background: #e7e7e7;
  height: 1px;
  width: 40% !important;
  position: absolute;
  right: 0;
  top: 4px;
}

.notification-message .right-data {
  font-size: 12px;
  color: #7a7a7a;
  font-weight: 400;
}

.loader {
  width: 100%;
  height: 2px;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}

.loader::after {
  content: "";
  width: 192px;
  height: 4.8px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }

  100% {
    left: 100%;
    transform: translateX(0%);
  }
}

.loader-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0a2e2e;
  left: 0;
  display: flex;
  z-index: 999;
  top: 0;
  align-items: center;
}

.logo-loader {
  width: 300px;
  margin: 8rem auto;
  text-align: center;
}

.loader-box .logo-loader img {
  width: 100%;
  max-width: 115px;
}

.participants .icon label {
  position: absolute;
  top: 0px;
  font-size: 8px;
  background: #54f2f3;
  width: auto;
  padding: 0px 5px !important;
  right: 10px !important;
  border-radius: 12px;
  color: #000;
}

.comments .icon label {
  position: absolute;
  top: 0px;
  font-size: 8px;
  background: #54f2f3;
  width: auto;
  padding: 0px 5px !important;
  right: 12px !important;
  border-radius: 12px;
  color: #000;
}

.errorpage-section h2 {
  font-size: 80px;
  font-family: "Gotham Pro";
}

.errorpage-section h4 {
  font-size: 32px;
  font-family: "Roboto";
}

h2.abslt-txt {
  color: #f2f2f2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
  font-size: 20rem;
}

.errorpage-section {
  height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.errorpage-section a {
  border-radius: 6px;
  background: linear-gradient(180deg, #202125 0%, #1b5052 100%);
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: Gotham Pro !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s all ease;
  text-transform: uppercase;
  padding: 12px 20px;
}

.errorpage-section {
  height: calc(100vh - 140px);
}

.errorpage-section a:hover {
  opacity: 0.9;
}

.offline h2.abslt-txt {
  font-size: 70px;
}

.edit-profile .modal-content {
  width: 744px !important;
}

.change-background img {
  width: 100%;
  max-width: 65%;
  height: 200px;
  object-fit: cover;
}

.change-background .modal-content .modal-header h5 {
  margin: 0 !important;
  font-size: 16px;
  font-family: "Gotham Pro" !important;
  font-weight: 400;
  color: #000;
}

.change-background .modal-content .modal-body p {
  font-size: 14px;
  font-family: "Roboto";
}

.change-background .modal-content .modal-body h5 {
  font-size: 18px;
  font-family: "Roboto";
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  border-radius: 6px;
  background: linear-gradient(180deg, #202125 0%, #1b5052 100%);
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: Gotham Pro !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s all ease;
  /* text-transform: uppercase; */
  padding: 12px 16px;
}

.nav-item.dropdown.options {
  position: absolute;
  right: 0;
  top: 24px;
}

.nav-item.dropdown.options a:after {
  display: none;
}

.nav-item.dropdown.options {
  position: absolute;
  right: 18px;
  top: 20px;
}

.nav-item.dropdown.options a {
  background: #ffffff;
  /* font-size: 14px; */
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  padding: 5px 14px;
  border-radius: 6px;
}

.nav-item.dropdown.options a:hover {
  background: #f2f2f2;
}

.edit-profile ul.dropdown-menu {
  width: 200px;
  transform: translate(-168px, 40px) !important;
}

button.text-with-underline {
  background: transparent;
  font-size: 14px;
  text-decoration: underline;
  color: #6ebec5;
  font-weight: 500;
}

button.save-changes {
  border-radius: 6px;
  background: linear-gradient(180deg, #202125 0%, #1b5052 100%);
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: Gotham Pro !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s all ease;
  text-transform: uppercase;
  border: none;
  padding: 12px 16px;
}

.profile-bio button.save-changes {
  border-radius: 6px;
  background: linear-gradient(180deg, #202125 0%, #1b5052 100%);
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: Gotham Pro !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s all ease;
  text-transform: uppercase;
  border: none;
  padding: 12px 16px;
}

.profile-bio textarea {
  resize: none;
  height: 200px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  margin: 0 !important;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 300;
  color: #000;
}

.edit-profile .modal label.form-label.m-0 {
  font-size: 12px;
  font-family: "Roboto";
  color: #999999;
}

.profile-bio textarea:focus-visible {
  outline: none;
}

textarea {
  border-radius: 6px;
  border-color: #dee2e6;
}

#picture__input {
  display: none;
}

.picture__img {
  max-width: 100%;
}

.edit-profile input,
.edit-profile textarea {
  font-size: 14px;
}

.addprofile-picture img {
  width: 100% !important;
  height: 100% !important;
  max-width: 330px !important;
  object-fit: contain !important;
  position: relative !important;
  top: unset !important;
}

.banner-auth .banner-heading {
  color: #0a2f30;
  font-family: Roboto;
  font-size: 50px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 50px;
}

.banner-auth form .form-label {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
}

.banner-auth form .form-control,
.banner-auth form .form-select {
  border-radius: 6px;
  border: 1px solid #092e2f !important;
  background: #fff;
  padding: 7px;
}

.banner-auth form .forgot-link {
  color: #092e2f;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.banner-auth form .login-btn {
  border-radius: 6px;
  border: 1px solid #0a2f30;
  background: rgba(210, 240, 255, 0.41);
  width: 100%;
  color: #092e2f;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 14px;
}

.or-divider {
  position: absolute;
  top: -6px;
  background: #f8f8f8;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 5px;
  color: #757575;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.banner-auth .fb-button {
  border-radius: 6px;
  background: #3b5998;
  padding: 10px;
  width: 100%;
  display: inline-block;
  text-align: center;
  color: #fff;
  text-decoration: none;
  line-height: normal;
}

.banner-auth .google-btn {
  border-radius: 6px;
  background: #fff;
  padding: 10px;
  width: 100%;
  display: inline-block;
  text-align: center;
  color: #000;
  text-decoration: none;
  line-height: normal;
  border: 1px solid #000;
}

.who_we_are_section {
  background-color: #f3fbff;
  margin-top: 50px;
}

.who_we_are_section .who-heading {
  color: #0a2f30;
  font-size: 50px;
  font-weight: 400;
  line-height: normal;
}

.who_we_are_section p {
  color: #092e2f;
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
}

.explore-section {
  padding: 74px 0;
}

.explore-section .article-pills .single-pill {
  padding: 14px 18px;
  border-radius: 6px;
  border: 1px solid #000;
  color: #092e2f;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.explore-section .heading-crcl {
  padding: 20px;
  height: 380px;
  width: 380px;
  border-radius: 50%;
  background-color: #f3fbff;
}

.explore-section p {
  color: #092e2f;
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  /* 130% */
}

.guest-footer {
  padding: 50px 0 30px;
  background-color: #092e2f;
}

.footer-signle-col p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.guest-footer .footer-signle-col ul li {
  list-style: none;
}

.guest-footer .footer-signle-col ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin: 10px 0;
  text-decoration: none;
}

.terms-text {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 2;
}
/* 
@media only screen and (max-width: 1199px)
{
  a.navbar-brand img{
    max-width: 140px;
  }

} */

@media only screen and (max-width: 767px) {

  a.navbar-brand img {
    width:65px !important;
    max-width: unset;
  }

  nav.navbar{
    height: 120px;
    align-items: baseline;
  }
  .container.main{
    margin-top:7.7rem !important;
  }
  .message-box .message-field {
    margin-bottom: 60px;
}
  .banner-auth .banner-heading,
  .who_we_are_section .who-heading {
    font-size: 35px;
  }

  .banner-auth .banner-heading,
  .who_we_are_section .who-heading {
    font-size: 35px;
  }

  .offline h2.mt-4.mb-2 {
    font-size: 30px;
  }

  .message-one .user-info .user {
    max-width: 30px !important;
    height: 30px !important;
  }

  .errorpage-section {
    height: calc(100vh - 200px);
  }

  h2.abslt-txt {
    color: #efefef87;
    font-size: 10rem;
  }

  .loader-box {
    align-items: center;
  }

  .logo-loader {
    margin: -4rem auto 0;
  }

  .logo-loader {
    width: 220px;
  }

  .setting-tabs .setting-content {
    margin-top: 1rem;
  }

  .setting-content p.stat-title:before,
  .setting-content p.stat-title:after {
    width: 24% !important;
  }

  .hold-end {
    margin-top: 1rem;
  }

  .side-tab .list-unstyled li a {
    text-decoration: none;
    font-size: 18px;
  }

  .nav-pills:hover .nav-link {
    color: #000;
    font-size: 16px;
  }

  .login .form-content {
    padding: 15px;
  }

  .messages ul li a .name-message p.message,
  ul.posts-list li a .post-content h4,
  .hashtags-list a,
  .card .user-details p,
  .notification-message .right-data {
    font-size: 14px !important;
  }

  .second-tab .title-with-icon p,
  .write-post .btn-group button p,
  ul.posts-list li .post-content p {
    font-size: 12px;
  }

  .message-box-header h4,
  .card .user-details h3 {
    font-size: 16px;
  }

  .msg-check.text-start p,
  .recieved-messages.sender .msg-check.text-start p {
    font-size: 14px;
  }

  .messages ul li a .name-message h4 {
    font-size: 16px;
  }

  .Skills .row .col-md-4 {
    margin-bottom: 10px;
  }

  .video-chat-btn {
    flex-direction: column;
  }

  .video-chat-btn .btn-with-icon {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .profile-right-bar {
    display: none;
  }

  .profile-page .profile-tabs li {
    width: 100%;
  }

  .profile-page .profile-data {
    flex-direction: column;
  }

  ul.dropdown-menu.show {
    left: -190px;
  }

  .advertise {
    position: relative;
    top: unset;
  }

  .filters {
    position: relative;
    top: unset;
  }

  .popular-posts {
    position: relative;
    top: unset;
  }

  .sticky-side {
    position: relative;
    top: unset;
  }

  .write-post .btn-group.d-flex {
    padding: 0px;
  }

  .write-post .btn-group button p {
    font-size: 10px;
  }

  .write-post .btn-group.d-flex button {
    padding: 10px 8px !important;
  }

  .profile .thumb {
    max-width: 30px;
    margin-right: 6px;
    height: 30px;
  }

  div#navbarSupportedContent ul.navbar-nav li {
    margin: 0 10px !important;
  }

  div#navbarSupportedContent {
    justify-content: right;
  }

  form.mx-5.search-bar {
    margin: 0 !important;
    width: 400px;
    position: absolute !important;
    top: 70px;
  }

  .search-bar button {
    display: block !important;
  }

  .profile-menu li.nav-item.dropdown a .profile p {
    display: none;
  }

  .search-bar .d-flex {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {

  /* a.navbar-brand img {
    max-width:120px;
  } */
  .sr-message p {
    font-size: 14px;
  }

  .video-box .video-chat-btn .hold-end .hold,
  .video-box .video-chat-btn .hold-end .end {
    padding: 6px 16px;
  }

  .video-box .video-chat-btn .btn-with-icon button {
    background: transparent;
    color: #ffff;
    margin: 0 !important;
  }

  .video-box .video-chat-btn .btn-with-icon button span {
    font-size: 9px;
  }

  .stats ul.stat-list h2 {
    font-family: Gotham Pro;
    font-size: 32px;
  }

  .profile-right-bar {
    display: none;
  }

  .second-tab .title-with-icon p {
    font-size: 8.5px;
  }

  .profile-data {
    flex-direction: column;
  }

  .side-tab.popular-posts.second-tab.py-4.px-4,
  .side-tab.px-4.py-4.position-relative {
    padding: 16px !important;
  }

  .stars-quantity {
    padding: 0 6px;
    height: 14px;
  }

  .message-one .user-info .user {
    max-width: 35px;
    height: 35px;
  }

  .time-options span {
    margin: 0 !important;
  }

  .stars-quantity img {
    width: 34px;
  }

  div#navbarSupportedContent ul {
    margin: 0 !important;
  }

  .profile-menu li.nav-item.dropdown a {
    display: block;
  }

  .navbar-nav .nav-item a span {
    display: none !important;
  }

  .navbar-nav ul.navbar-nav {
    margin: 0 !important;
  }

  .right {
    display: none;
  }

  .write-post .btn-group.d-flex button {
    padding: 10px 12px;
  }
}

@media only screen and (max-width: 1000px) {
  .messages ul li a .name-message p.message {
    font-size: 11px !important;
  }
  .connection-list li a {
    text-decoration: none;
    flex-direction: column;
  }

  .connection-list li a button.connect {
    width: 80%;
    margin-top: 12px;
  }

}

@media only screen and (max-width: 1200px) {


  .explore-section .heading-crcl {
    height: 300px;
    width: 300px;
  }

  .write-post .btn-group.d-flex {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 650px){
  .message_box,.search_user_box{
    right: 20px !important;
  }

}


@media only screen and (max-width: 575px){
  a.navbar-brand{
    margin: 0 4px !important;
  }


  nav.navbar {
    height: 130px;
}
  form.mx-5.search-bar {
    width: 92%;
    top: 77px;
    left: 4%;

}


}


/*# sourceMappingURL=style.css.map */

.avatar-text {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.avatar-profile-image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

.main-post-btn {
  border: 0 !important;
  font-size: 16px;
  resize: none;
  font-family: "Roboto";
  width: 100%;
  font-weight: 400;
  color: #666666;
  width: 100%;
  background-color: #fff !important;
  text-align: left !important;
}

.main-post-btn:hover,
.main-post-btn:focus,
.main-post-btn:visited {
  border: 0 !important;
  background-color: #fff !important;
  outline: 0 !important;
  box-shadow: none;
}

.add-post-modal .add-media input[type="file"] ~ label {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
}

@media only screen and (min-width: 767px) {
  .add-post-modal .modal-dialog {
    max-width: 700px !important;
  }
}

img.like_btn_click {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(148deg);
}

.card .button-group .set-one a img.like_btn_click {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(148deg);
}

.side-tab.second-tab .swiper,
.post-img-wrapper .swiper {
  width: 100%;
  height: 100%;
}

.side-tab.second-tab .swiper-slide,
.post-img-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-tab.second-tab .swiper-slide img,
.post-img-wrapper img {
  display: block;
  width: 80%;
  height: 80%;
  object-fit: cover;
  margin: auto;
}

.user-profile-dicrpt-img {
  height: 85px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 16px 16px 0 0;
}

/*======= search profile view page css ======== */

.profile-holder .bg-holder {
  background-image: url(/src/Components/Assets/images/food.webp);

  position: relative;

  width: 100%;

  background-size: cover;

  background-position: center;

  background-repeat: no-repeat;

  border-radius: 8px 8px 0 0;

  min-height: 23vh !important;

  margin-bottom: 70px;
}

.avatar-profile {
  position: absolute;

  bottom: 0;

  transform: translateY(50%);

  height: 8rem;

  width: 8rem;

  left: 1.5rem;
}

.avatar img {
  width: 100%;

  height: 100%;

  -o-object-fit: cover;

  object-fit: cover;
}

.profile-holder .company-detail p {
  line-height: 1;

  margin-bottom: 3px;
}

.about-section p {
  color: gray;

  font-size: 14px;
}

.search-profile-tab li.nav-item {
  width: auto;
}

.search-profile-tab li.nav-item button {
  padding: 10px 20px;
}

/*====== my network page css ======== */

.invitation-list-wrapper .list-group li:last-child {
  border-radius: 0px 0px 15px 15px;
}

.manage-invitation-profile li.nav-item .nav-link {
  border-bottom: 2px solid transparent !important;

  font-weight: 500;

  color: gray;
}

.manage-invitation-profile li.nav-item .nav-link.active {
  color: #062a2b;

  border-color: #062a2b !important;
}

.invitation-list-wrapper .follower-area .date-wrapper {
  font-size: 12px;

  line-height: 1;
}

.user-profile-dicrpt-img .user-img {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.user-profile-dicrpt-img .user-img img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 50%;
}

.liked-comment {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(148deg);
}

.net-img {
  filter: brightness(0) invert(1);
}

/* Loader styles */
.loader-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader1 {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #092e2f;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* comment section css */
.post-comment-field {
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 15px;
}

.right-comment-form {
  width: 80%;
}

.right-comment-form h5 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}

.right-comment-form p {
  color: gray;
  margin: 0;
  font-size: 14px;
  line-height: normal;
}

.right-comment-form input {
  border: 0;
  background: transparent;
  height: 43px;
  margin-top: 10px;
  font-size: 15px;
  outline: none;
}

.right-comment-form button.btn.post-save {
  background: #1d3f40;
  color: white;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 14px;
}

.right-comment-form button.btn.post-cancel {
  border: 2px solid gray;
  color: gray;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 14px;
}

.time-options .dropdown-toggle::after {
  display: none !important;
}

.write-message .user-img,
.add-media img {
  width: 100%;
  max-width: 50px;
  object-fit: cover;
  height: 50px;
  border-radius: 100px;
}

.add-media img:not(:last-child) {
  margin-right: 5px;
}

.search-dropdown-menu button {
  display: unset !important;
  width: 100%;
}

.search-dropdown-menu .dropdown-toggle::after {
  display: none;
}

.search-dropdown-menu .btn:hover,
.search-dropdown-menu .btn:active,
.search-dropdown-menu.show > .btn-success.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
}

.search-dropdown-menu .btn:focus {
  box-shadow: unset;
}

.search-dropdown-menu .dropdown-menu {
  width: 100%;
  max-width: 100%;
}

body.modal-open {
  padding: 0 !important;
  overflow-y: scroll !important;
}

.reply-section-message .user-img {
  max-width: 40px !important;
  height: 40px;
}

.reply-section-message input[type="text"] {
  font-size: 13px !important;
  padding: 8px 93px 8px 53px !important;
  background-color: #fff !important;
}

.reply-section-message .sending-opt {
  top: 7px;
  right: 0px;
}

.reply-comment-box {
  width: 90%;
  margin-left: auto;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .invitation-list-wrapper .user-field {
    width: 100% !important;
  }
}

@media (max-width: 575.98px) {
  .invitation-list-wrapper .user-field {
    width: 100% !important;
  }
  .rating-box{
    position: unset;
  }
  
}

@media (max-width: 465.98px) {
  .sr-message{
    width: 70%;
  }
  .avatar-text{
    width: 33px;
    height: 33px;
  }
}


@media (max-width: 365.98px) {
  .sr-message{
    width: 60%;
  }

}

.placeholder-glow .placeholder {
  background-color: #c4c4c4 !important;
}

.placeholder-wave {
  background-color: #e2e2e2 !important;
}
.rating-box i {
  font-size: 16px;
}

.job-card .job-title {
  font-size: 20px;
  font-weight: 500;
}

.recieved-messages .msg-check .img-attachment {
  background: #ecf6f7;
  padding: 10px;
}
.recieved-messages .msg-check .img-attachment img {
  max-width: 150px;
  max-height: 200px;
  width: 100%;
}
.recieved-messages .msg-check .doc-attachment .card {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 5px;
  border-radius: 5px;
}

.recieved-messages .msg-check .doc-attachment .card .file-name {
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 500;
  flex-grow: 1;
  color: #000;
}
.recieved-messages
  .msg-check
  .doc-attachment
  .card
  .dropdown
  .dropdown-toggle::after {
  display: none;
  border: 0 !important;
}
.recieved-messages .msg-check .doc-attachment .card .dropdown .dropdown-toggle,
.recieved-messages
  .msg-check
  .doc-attachment
  .card
  .dropdown
  .dropdown-toggle:focus {
  border: 0 !important;
  box-shadow: none;
  border: 0;
}
.recieved-messages
  .msg-check
  .doc-attachment
  .card
  .dropdown
  .dropdown-toggle
  img {
  filter: grayscale(100%);
}

.cursor-pointer {
  cursor: pointer !important;
}

/* chhat box css */
.rightside-popup-wrapper {
  position: fixed;
  bottom: 0px;
  right: 15px;
  width: 300px;
  z-index: 9;
  box-shadow: 0px 4.33253px 32.494px 0px rgba(0, 0, 0, 0.2);
}
.message_box, .search_user_box {
  position: fixed;
  bottom: 0px;
  right: 325px;
  width: 300px;
  z-index: 9;
  box-shadow: 0px 4.33253px 32.494px 0px rgba(0, 0, 0, 0.2);
}
.open-button {
  background-color: rgba(4, 42, 43, 1);
  color: white;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  border-radius: 8.63px 8.63px 0px 0px;
}

.rightside-popup-wrapper.show button.open-button .downChev_icon {
  rotate: 180deg;
}

.chat-img img {
  width: 32px;
  object-fit: cover;
  height: 32px;
  border-radius: 100px;
}
.messaging-heading {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.chat-img .active-status-dot,
.chat-messaging-list li.active .active-status-dot {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 10px;
  width: 10px;
  background-color: rgba(84, 242, 243, 1);
  border: 1px solid #fff;
  border-radius: 50%;
}

.chat-svg-listing {
  list-style: none;
}

.chat-popup-screen {
  max-height: 60vh;
  overflow-y: auto;
}

.chat-popup-screen ul li {
  padding: 8px 15px;
  cursor: pointer;
}
.chat-popup-screen ul li:hover {
  background: rgba(94, 177, 191, 0.1);
}

.chat-popup-screen {
  background: #fff;
}
.chat-popup-screen .sub-heading {
  color: #a7a7a7;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.chat-popup-screen .flex-box {
  height: 200px;
  overflow-y: auto;
}

.chat-messaging-list li.active {
  background: rgba(94, 177, 191, 0.1);
}

.chat-messaging-list li.active::before {
  background: #54f2f3;
  position: absolute;
  left: 0;
  content: "";
  width: 5px;
  height: 100%;
}

.chat-messaging-list li .active-status-dot {
  background-color: rgba(228, 228, 228, 1);
}

.chat-svg-listing .dropdown-toggle::after,
.chat-messaging-list .dropdown-toggle::after {
  display: none;
}
.chat-svg-listing .dropdown-toggle,
.chat-svg-listing .dropdown-toggle:hover,
.chat-svg-listing .dropdown-toggle:active,
.chat-messaging-list .dropdown-toggle,
.chat-messaging-list .dropdown-toggle:hover,
.chat-messaging-list .dropdown-toggle:active,
.chat-messaging-list .dropdown.show .dropdown-toggle {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.chat-messaging-list .dropdown-toggle:focus,
.chat-messaging-list .dropdown-toggle:focus {
  box-shadow: unset !important;
}

.chat-messaging-list .dropdown a {
  font-size: 14px;
}

.chat-message-bar input[type="text"] {
  width: 100%;
  border-radius: 18px;
  background: #f6f6f6;
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-style: normal;
  padding: 14px 93px 14px 53px;
  font-weight: 400;
  line-height: normal;
  border: none;
}
.chat-message-bar .sending-opt {
  position: absolute;
  top: 14px;
  right: 0;
}

.chat-user-img {
  width: 28px;
  object-fit: cover;
  height: 28px;
  border-radius: 100px;
  position: absolute;
  top: 14px;
  left: 16px;
}

.modal .modal-header {
  background-color: rgba(4, 42, 43, 1);
  color: #fff;
}

.modal-header .btn-close {
  filter: invert(1);
}

.modal-backdrop {
  --bs-backdrop-bg: #819191 !important;
}
.bookmarked img {
  filter: invert(21%) sepia(100%) saturate(2708%) hue-rotate(217deg)
    brightness(110%) contrast(98%);
}

.select-post-type {
  font-size: 18px !important;
  background-color: transparent !important;
  color: #fff !important;
  border-color: #54f2f3 !important;
  padding: 3px 40px 3px 8px !important;
  --bs-form-select-bg-img: url("../images/white-chevron-down.svg") !important;
}

.select-post-type option {
  color: #000;
}

.add-post-modal .form-control,
.add-post-modal .form-control:focus {
  border-color: rgb(6 42 43) !important;
  background-color: #f3f6f6;
  padding: 0.375rem 0.75rem !important;
}

.timing-divide .timing-pill {
  position: absolute;
  background-color: #ecf6f7;
  padding: 5px 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  border-radius: 20px;
}


.search-bar-inner-wrapper{
  background: rgba(94, 177, 191, 0.1);
  border-radius: 6px;
  padding: 0px 15px;
  display: flex;
}


.popular-posts .accordion-button {
  padding: 10px;
}

.sticky-side .accordion .accordion-item{
 border: 0;
}

.sticky-side .accordion-button:not(.collapsed)
{
background-color: #fff;
box-shadow: none;
}

.sticky-side .accordion-button:focus:not(:focus-visible)
{
outline: none;
}
.sticky-side .accordion-button:focus{
  border-color: inherit;
  box-shadow: none;
}

.sticky-side button.accordion-button {
  border-radius: 20px;
}

.report-post-icon{
  width:20px !important;
}

.message-option .dropdown-toggle::after{
  display: none;
}

.message-option  .dropdown button{
  background-color: transparent;
  border: 0;
}

.comment-input-wrapper{
  width: 90%;
}

.seach-filter-byhash-icon {
  padding: 7px 8px;
  border-radius: 6px;
}
.seach-filter-byhash input{
  height: 28px;
}
.seach-filter-byhash-icon i{
  font-size: 12px;
}



::placeholder {
  color: #aaaaaa;
  opacity: 1; 
}


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #aaaaaa;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #aaaaaa;
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    #aaaaaa;
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    #aaaaaa;
}

img.footer-logo {
  width: 100%;
}
.connection-border-div .search-profile-wraper:not(:last-child){
  border-bottom: 1px solid #ddd;
}
.view-all-btn{
  border-radius: 6px;
  border: 1px solid #1b5052;
  text-decoration: none !important;
  color: #000 !important;
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
  float: right;
}
